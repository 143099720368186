import React, {useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom'
import {Button, Form, FormControl, InputGroup, Spinner} from 'react-bootstrap';
import GameApi from './api/GameApi';
import GameList from './GameList';
import Play from './Waiting';
import CONSTANTS from "./utils/CONSTANTS";

function Home() {

    //  States
    const history = useHistory()
    const [games, setGames] = useState([]);
    const [gameId, setGameId] = useState('');
    const [name, setName] = useState('');
    const [player, setPlayer] = useState(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        // code
        GameApi.getAllGames().then(games => setGames(games));
    }, []);

    function createGame() {
        GameApi.createGame().then(game => {
            GameApi.joinGame(game.gameId, name).then(player => {
                localStorage.setItem(CONSTANTS.LOCAL_STORAGE.PLAYER, JSON.stringify(player));
                history.push('/waiting' + '/' + game.gameId)
            });
        })
    }

    function handleCreateGame(event) {
        event.preventDefault();
        setLoading(true);
        //setTimeout(createGame, 2000)
        createGame();
    }

    function handleJoinGame(event) {
        event.preventDefault();
        GameApi.joinGame(gameId, name).then(player => {
            localStorage.setItem(CONSTANTS.LOCAL_STORAGE.PLAYER, JSON.stringify(player));
            history.push('/play' + '/' + gameId)
        });
    }

    if (player) return <Play player={player} gameId={gameId}/>

    return <div>
        <Form className="m-4" onSubmit={handleCreateGame}>
            <InputGroup>
                <FormControl placeholder="Name" required onChange={e => setName(e.target.value)}/>
                <Button type='submit'>

                    {
                        loading && <Spinner size='sm' animation="border"/>
                    }
                    Create Game
                </Button>

            </InputGroup>
        </Form>
        <hr/>
        <h2>Or</h2>
        <hr/>
        <Form className="m-4" onSubmit={handleJoinGame}>
            <InputGroup>
                <FormControl placeholder="Name" required onChange={e => setName(e.target.value)}/>
                <FormControl placeholder="Game ID" required onChange={e => setGameId(e.target.value)}/>
                <InputGroup.Append>
                    <Button type="submit">Join Game</Button>
                </InputGroup.Append>
            </InputGroup>
        </Form>
    </div>
}

export default Home;