import React from 'react';
import './App.css';
import AppBanner from './components/AppBanner'
import Home from './components/Home';
import 'bootstrap/dist/css/bootstrap.min.css';
import Waiting from './components/Waiting'
import Play from './components/Play'
import { Switch, Route } from 'react-router-dom'

function App() {
  return (
   
    <div className="App">
      <header className="App-header">
      <AppBanner/>
      <hr />

      <Switch>
        <Route path='/' exact component={Home} />
        <Route path='/waiting/:gameId' exact component={Waiting} />
        <Route path='/play/:gameId' exact component={Play} />
      </Switch>

      </header>
    </div>
  );
}

export default App;
