import axios from 'axios';
import CONSTANTS from '../utils/CONSTANTS';

class PlayApi {

static playGame(gameId, row, col, playerId){
    
    const url = CONSTANTS.URL.PLAY + '/' + gameId;
    const payload = { row: row, col: col, playerId: playerId}
    
    return axios.post(url, payload).then(response => response.data);
}

static rematch(gameId) {
    const url = CONSTANTS.URL.GAME + '/' + gameId;
    return axios.post(url)
}
    
  
}

export default PlayApi
