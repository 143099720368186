const BASE_URL = 'https://tic.roti.io';

export default {
    URL: {
        BASE: BASE_URL,
        GAME : BASE_URL + '/game',
        PLAY: BASE_URL + '/play'
    },
    LOCAL_STORAGE: {
        PLAYER: 'PLAYER'
    }
}