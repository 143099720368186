import React, { useState, useEffect } from "react";
import GameApi from "./api/GameApi";
import { useParams } from "react-router-dom";

function Waiting({ history }) {
  const [game, setGame] = useState(null);
  const { gameId } = useParams();
  const [timer, setTimer] = useState(null);

  useEffect(() => {
    setTimer(setInterval(()=> {
      getGame()
  }, 1000))
  }, []);

  function getGame() {
      GameApi.getGame(gameId).then((game) => {
        setGame(game)
        if (game.state === 'PLAY') {
          clearInterval(timer)
          history.push('/play' + '/' + gameId)
        }
    });
  }

  if (!game) return null;

  return <div>
    <h1 className="player-name">
      {game.player1.name}
    </h1>
    <h4>
      Symbol: {game.player1.symbol}
    </h4>
    <hr />
    <h2>
      Game ID
          </h2>
    <p>{gameId}</p>
    <hr />
    <h2>Waiting for 2nd player...</h2>
  </div>
}
export default Waiting;
