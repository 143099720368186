import React from 'react'
import { Nav } from 'react-bootstrap';

function AppBanner() {
    return(
        <div>
            <Nav variant="pills" defaultActiveKey="/">
            <Nav.Item className="mt-2" >
            <Nav.Link className="px-5" href="/"><h2>Tic Tac Toe<h6>Home</h6></h2></Nav.Link>
        
            </Nav.Item>
            </Nav>
        </div>
    )
}
export default AppBanner;