import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import GameApi from "./api/GameApi";
import PlayApi from "./api/PlayApi";
import './Play.css'
import CONSTANTS from "./utils/CONSTANTS";
import {Alert, Button} from "react-bootstrap";

function Play() {

    const [game, setGame] = useState(null);
    const [player, setPlayer] = useState();
    const {gameId} = useParams();

    useEffect(() => {
        // Get player from local state
        const savedPlayer = localStorage.getItem(CONSTANTS.LOCAL_STORAGE.PLAYER);
        if (savedPlayer) {
            setPlayer(JSON.parse(savedPlayer));
        }
    }, []);


    useEffect(() => {
        setInterval(() => {
            getGame()
        }, 1000)
    }, []);

    function getGame() {
        GameApi.getGame(gameId).then(game => {
            setGame(game)
        })
    }

    function clickHandler(event) {
        event.preventDefault()
        const row = parseInt(event.target.getAttribute('row'))
        const col = parseInt(event.target.getAttribute('col'))
        const {playerId} = player;
        PlayApi.playGame(gameId, row, col, playerId).then(game => setGame(game))

    }

    function rematch() {
        PlayApi.rematch(gameId)
    }

    function isMyTurn() {
        if (!player) return false;
        return player.playerId === game.turn.playerId
    }

    if (!game) return "waiting"

    return <div>

        <Alert variant='dark'>
            {isMyTurn() ? 'My Turn' : "Opponent's turn"}
        </Alert>
        <div className="mainBoard">
            <div className="box" id="box1" onClick={clickHandler} row={0}
                 col={0}>{game.board[0][0] && game.board[0][0].symbol}</div>
            <div className="box" id="box2" onClick={clickHandler} row={0}
                 col={1}>{game.board[0][1] && game.board[0][1].symbol}</div>
            <div className="box" id="box3" onClick={clickHandler} row={0}
                 col={2}>{game.board[0][2] && game.board[0][2].symbol}</div>
            <div className="box" id="box4" onClick={clickHandler} row={1}
                 col={0}>{game.board[1][0] && game.board[1][0].symbol}</div>
            <div className="box" id="box5" onClick={clickHandler} row={1}
                 col={1}>{game.board[1][1] && game.board[1][1].symbol}</div>
            <div className="box" id="box6" onClick={clickHandler} row={1}
                 col={2}>{game.board[1][2] && game.board[1][2].symbol}</div>
            <div className="box" id="box7" onClick={clickHandler} row={2}
                 col={0}>{game.board[2][0] && game.board[2][0].symbol}</div>
            <div className="box" id="box8" onClick={clickHandler} row={2}
                 col={1}>{game.board[2][1] && game.board[2][1].symbol}</div>
            <div className="box" id="box9" onClick={clickHandler} row={2}
                 col={2}>{game.board[2][2] && game.board[2][2].symbol}</div>
        </div>
        <hr/>
        {game.state === 'CLOSED' && (game.winner ?
                (game.winner.playerId === player.playerId ? <h1>😊 Winner!</h1> : <h1> 😢 Loser!</h1>):
                <h1>😟 Tie</h1>
        )}
        <hr/>
        {game.state === 'CLOSED' && <Button type="submit" onClick={rematch}>Rematch</Button>}

    </div>

}

export default Play