import axios from 'axios';
import CONSTANTS from '../utils/CONSTANTS';

class GameApi {

    static GAME = CONSTANTS.URL.GAME;

    static getAllGames() {
        return axios.get(this.GAME).then(response => response.data);
    }

    static getGame(gameId) {
        return axios.get(this.GAME + '/' + gameId).then(response => response.data);
    }

    static createGame() {
        return axios.post(this.GAME).then(response => response.data);
    }

    static joinGame(gameId, name) {
        const url = this.GAME + '/' + gameId;
        const payload = {name: name};
        return axios.put(url, payload).then(response => response.data);
    }
}

export default GameApi;